@import '../../styles/dimensions';

.section {
    margin: $gap-large * 2 0;
    padding: $gap-large 0;
}

.form-section {
    align-items: center;
    display: flex;
    flex-direction: column;

    // allow the form to control the input width
    label {
        max-width: 100%;
        width: 100%;
    }
}
